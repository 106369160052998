// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

Rails.start()
ActiveStorage.start()

/* kamiliff default behavior */
window.addEventListener("liff_ready", function(event){
  register_kamiliff_submit();
});

window.addEventListener("liff_submit", function(event){
  var json = JSON.stringify(event.detail.data);
  var url = event.detail.url;
  var method = event.detail.method;
  var request_text = method + " " + url + "\n" + json;
  liff_send_text_message(request_text);
});

// blur hash 在頁面還沒載入完就要先執行
import { decodeBlurHashes } from '../components/blurhash_decoder';
decodeBlurHashes();

// lazy load image 在頁面載入完之後才執行
import { lazyLoadImage } from '../components/lazy_load_image';
window.addEventListener('load', lazyLoadImage);