import { decode } from 'blurhash';

export function decodeBlurHashes() {
    let containers = document.querySelectorAll(".blurhash-container");
    containers.forEach(container => {
        let blurhashString = container.getAttribute("data-blurhash");
        let canvas = container.querySelector(".blurhash-canvas");

        let viewportWidth = container.parentNode.clientWidth;
        let aspectRatio = parseFloat(container.getAttribute("data-aspect-ratio")) || 16/9; // 使用容器的比例或預設值 1（正方形）
        let computedHeight = viewportWidth / aspectRatio;

        canvas.width = viewportWidth;
        canvas.height = computedHeight;

        let decodedData = decode(blurhashString, viewportWidth, computedHeight);
        let ctx = canvas.getContext('2d');
        let imageData = ctx.createImageData(viewportWidth, computedHeight);

        for (let i = 0; i < decodedData.length; i++) {
            imageData.data[i] = decodedData[i];
        }

        ctx.putImageData(imageData, 0, 0);
    });
}
