export function lazyLoadImage() {
  // 获取所有带有 lazy-load-image 类的图像标签
  var lazyLoadImages = document.querySelectorAll('.lazy-load-image');

  // 遍历每个图像标签，将 data-src 属性的值设置为 src
  lazyLoadImages.forEach(function(img) {
    img.src = img.getAttribute('data-src');
    img.style.display = 'block';
  });
}
